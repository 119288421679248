const axios = require('axios');
axios.defaults.baseURL = process.env.REACT_APP_SERVICE_URL || 'http://localhost:7321';

const httpService = async (body) => {
    try {
        const resp = await axios.post('/sendData', body)
        return resp.status;
    } catch (err) {
        return err.response.status;
    }
}

export default httpService;