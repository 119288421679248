import * as React from 'react';
import './App.css';
import logo from './img/logo-qualabs-2019-.png';
import { qualabbers, projects } from './resources/formValues';
import { startDateTooltip, endDateTooltip, sameProjectTooltip } from './resources/formTooltips';
import InputLabel from '@mui/material/InputLabel';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import BasicSelect from './components/basic-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpDialog from './components/help-dialog';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import httpService from './services/httpService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SUCCESS, INVALID_ARGUMENTS, FORM_ERROR, INVALID_DATES } from './resources/errorCodes';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

function App() {
  const TODAY = new Date(new Date(0).setSeconds(new Date().setHours(0, 0, 0, 0)) / 1000);
  let aDate = new Date(new Date(0).setSeconds(new Date().setHours(0, 0, 0, 0)) / 1000);
  const LAST_MONDAY = new Date(new Date(0).setSeconds(aDate.setDate(aDate.getDate() - (aDate.getDay() + 6) % 7)) / 1000);
  const [sameProject, setSameProject] = React.useState(true);
  const [startDate, setStartDate] = React.useState(TODAY);
  const [endDate, setEndDate] = React.useState(null);
  const [name, setName] = React.useState(localStorage.getItem("name") || null);
  const [morningProject, setMorningProject] = React.useState(localStorage.getItem("morningProject") || null);
  const [afternoonProject, setAfternoonProject] = React.useState(localStorage.getItem("afternoonProject") || null);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('info');
  const [toastrMessage, setToastrMessage] = React.useState('default');
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const handleSameProject = (event) => {
    setSameProject(event.target.checked);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleToastrClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sendData = (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    let data = {
      "startDate": startDate,
      "endDate": endDate ? endDate : startDate,
      "name": name,
      "morningProject": morningProject,
      "afternoonProject": sameProject ? morningProject : afternoonProject
    };
    httpService(data).then((response) => {
      handleResponse(response);
      setOpen(true);
      setSubmitDisabled(false);
    }).catch((error) => {
      handleResponse(error);
      setOpen(true);
      setSubmitDisabled(false);
    });
  }

  const handleResponse = (errorCode) => {
    switch (errorCode) {
      case SUCCESS:
        setSeverity('success');
        setToastrMessage('Planilla llenada correctamente!');
        break;
      case INVALID_ARGUMENTS:
        setSeverity('error');
        setToastrMessage('Debes ingresar tu nombre y en qué proyecto trabajaste en la mañana.');
        break;
      case INVALID_DATES:
        setSeverity('error');
        setToastrMessage('La fecha ingresada no es correcta. Debe ser en formato US (MM/dd/yyyy).');
        break;
      case FORM_ERROR:
        setSeverity('error');
        setToastrMessage('Ha ocurrido un error en el formulario.');
        break;
    }
  }

  const handleName = (name) => {
    setName(name);
    localStorage.setItem("name", name);
  };

  const handleMorningProject = (morningProject) => {
    setMorningProject(morningProject);
    localStorage.setItem("morningProject", morningProject);
  };

  const handleAfternoonProject = (afternoonProject) => {
    setAfternoonProject(afternoonProject);
    localStorage.setItem("afternoonProject", afternoonProject);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div>
        <HelpDialog />
      </div>
      <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleToastrClose}>
        <Alert onClose={handleToastrClose} severity={severity} sx={{ width: '100%' }}>
          {toastrMessage}
        </Alert>
      </Snackbar>
      <FormGroup>
        <form onSubmit={sendData} >
          <div className='initial-date'>
            <Button variant="outlined" endIcon={<TodayIcon />} type='button' onClick={() => { setStartDate(TODAY); }}>
              Llenar hoy
            </Button>
            <Button variant="outlined" endIcon={<CalendarMonthIcon />} type='button' onClick={() => { setStartDate(LAST_MONDAY); setEndDate(TODAY); }}>
              Llenar semana
            </Button>
            <h4>
              <InputLabel id="demo-multiple-name-label">Inicio del rango a completar</InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Inicio"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Tooltip title={startDateTooltip} placement="right-start">
                <InfoIcon />
              </Tooltip>
            </h4>
          </div>

          <h4>
            <InputLabel id="demo-multiple-name-label">Final del rango a completar</InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fin"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Tooltip title={endDateTooltip} placement="right-start">
              <InfoIcon />
            </Tooltip>
          </h4>

          <h4>
            <div>
              <InputLabel id="demo-multiple-name-label">Quién sos?</InputLabel>
              <BasicSelect items={qualabbers} label="Nombre" onChange={handleName} value={name} />
            </div>
          </h4>

          <h4>
            <div>
              <InputLabel id="demo-multiple-name-label">En qué proyecto trabajaste en la mañana?</InputLabel>
              <BasicSelect items={projects} label="Proyecto" onChange={handleMorningProject} value={morningProject} />
            </div>
            <div>
              <FormControlLabel control={<Checkbox checked={sameProject} onChange={handleSameProject} />}
                label="Mismo proyecto en la tarde" />
              <Tooltip title={sameProjectTooltip} placement="right-start">
                <InfoIcon />
              </Tooltip>
            </div>
          </h4>

          <div>
            <h4>
              {!sameProject ? <div>
                <InputLabel id="demo-multiple-name-label">En qué proyecto trabajaste en la tarde?</InputLabel>
                <BasicSelect items={projects} label="Proyecto" onChange={handleAfternoonProject} value={afternoonProject} />
              </div>
                : ''}
            </h4>
          </div>

          <Button variant="contained" endIcon={<SendIcon />} type='submit' disabled={submitDisabled}>
            ENVIAR
            {submitDisabled && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
        </form>
      </FormGroup>
    </div>
  );
}

export default App;
