import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function HelpDialog(props) {
    return (
        <div className='infobox'>
            <Alert icon={false} severity="info">
                <AlertTitle>Información importante para completar el formulario</AlertTitle>
                1. Seleccioná el rango de fechas (las fechas son inclusivas) <br />
                2. Seleccioná quién sos <br />
                3. Seleccioná en qué proyecto trabajaste en la mañana <br />
                &nbsp;&nbsp;&nbsp;&nbsp;3.1. (Opcional) Seleccioná si trabajaste en el mismo proyecto en la tarde <br />
                &nbsp;&nbsp;&nbsp;&nbsp;3.2. (Opcional) Seleccioná en qué proyecto trabajaste en la tarde <br />
            </Alert>
        </div>
    );
}